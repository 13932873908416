.box-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 25px;
}

.video-box-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}

.progress-text {
  text-align: center;
  font-size: 15px !important;
  font-weight: bold !important;
  margin-top: 25px !important;
}

.modal-header {
  text-align: center;
  font-size: 15px !important;
  font-weight: bold !important;
  margin-bottom: 15px !important;
}

.modal-header-video {
  text-align: center;
  font-size: 15px !important;
  font-weight: bold !important;
  padding: 15px;
}

.modal-desc {
  text-align: center;
  font-size: 15px !important;
  font-weight: bold !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.close-button {
  text-align: center;
  font-size: 13px !important;
  font-weight: bold !important;
  text-decoration-line: underline !important;
  margin-top: 25px !important;
  color: #f01d22 !important;
  cursor: pointer !important;
}

.top-header-contianer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
