.container {
  /* overflows: scroll; */
  /* display: flex;
  text-align: justify;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 15px;
  justify-content: center; */
}

/* hide scrollbar for chrome, safari and opera */
#celeb-list::-webkit-scrollbar {
  display: none;
}

.box {
  margin: 1rem;
}

.swipe-button-container {
  width: 340px;
  height: 60px;
  background-color: #00000040;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: end;
  border: 1px solid #f01d22;
  padding-right: 1em;
  position: absolute;
  bottom: 22px;
  z-index: 1;
}

.swipe-button-slider {
  position: absolute;
  left: 3px;
  width: 53px;
  height: 53px;
  background-color: #f01d22;
  border-radius: 30px;
  cursor: pointer;
  transition: left 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiperLoadingButton {
  width: 56px;
  height: 56px;
  background-color: #f01d22;
  border-radius: 30px;
}

.swipe-button-container-free {
  width: 340px;
  height: 60px;
  background-color: #00000040;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: end;
  border: 1px solid #ACABAB;
  padding-right: 1em;
  position: absolute;
  bottom: 22px;
  z-index: 1;
}

.swipe-button-slider-free {
  position: absolute;
  left: 3px;
  width: 53px;
  height: 53px;
  background-color: #ACABAB;
  border-radius: 30px;
  cursor: pointer;
  transition: left 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiperLoadingButton-free {
  width: 56px;
  height: 56px;
  background-color: #ACABAB;
  border-radius: 30px;
}

.swiperLoadingButton > span {
  width: 30px;
  height: 30px;
  color: white;
}

.swipe-button-slider.active {
  transition: none;
}

.swipe-button-slider.swiped {
  background-color: #00c853;
}

.swipe-button-slider-free.swiped {
  background-color: #00c853;
}

.swipe-button-img {
  width: 13px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.swipe-button-img-free {
  width: 13px;
}

.buyButtonSwipper {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  background-color: transparent;
  border: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.buyButtonSwipper-free {
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  background-color: transparent;
  border: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
